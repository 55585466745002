import React from "react";

import testimonial1 from "../../images/testimonials/testimo1.png";
import testimonial2 from "../../images/testimonials/testimo2.png";
import testimonial3 from "../../images/testimonials/testimo3.png";

import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Rating,
  Box,
} from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const Carousel = () => {
  return (
    <div style={{ paddingBottom: "5vh" }}>
      <Swiper
        loop={true}
        className="mySwiper"
        slidesPerView={3}
        spaceBetween={20}
        breakpoints={{
          200: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
        }}>
        <SwiperSlide>
          <Card sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}>
              <Avatar
                alt="Remy Sharp"
                src={testimonial1}
                sx={{ width: 56, height: 56 }}
              />
              <Rating
                name="read-only"
                value={5}
                readOnly
                sx={{ fontSize: "20px", color: "#470865" }}
              />
            </Box>
            <CardContent sx={{ pl: 0, pr: 4 }}>
              <Typography sx={{ fontSize: 14 }}>
                “This is perfect for students, professionals and anyone who need
                to produce high quality essays. This is the best AI tool I’ve
                ever used”.
              </Typography>
            </CardContent>
          </Card>
        </SwiperSlide>

        <SwiperSlide>
          <Card sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}>
              <Avatar
                alt="Remy Sharp"
                src={testimonial2}
                sx={{ width: 56, height: 56 }}
              />
              <Rating
                name="read-only"
                value={5}
                readOnly
                sx={{ fontSize: "20px", color: "#470865" }}
              />
            </Box>
            <CardContent sx={{ pl: 0, pr: 4 }}>
              <Typography sx={{ fontSize: 14 }}>
                “The accuracy and efficiency of your CHATPDF have exceeded our
                expectations I'm impressed with the advanced capabilities and
                features offered by CHATPDF”.
              </Typography>
            </CardContent>
          </Card>
        </SwiperSlide>

        <SwiperSlide>
          <Card sx={{ p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}>
              <Avatar
                alt="Remy Sharp"
                src={testimonial3}
                sx={{ width: 56, height: 56 }}
              />
              <Rating
                name="read-only"
                value={5}
                readOnly
                sx={{ fontSize: "20px", color: "#470865" }}
              />
            </Box>
            <CardContent sx={{ pl: 0, pr: 4 }}>
              <Typography sx={{ fontSize: 14 }}>
                “CHATPDF have played a crucial role in enhancing our overall
                customer experience. we are grateful for the continuous
                improvements and updates”.
              </Typography>
            </CardContent>
          </Card>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
