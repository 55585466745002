import React from "react";
import "./home.css";
import { Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import gif1 from "../../images/home/videos/robot 1.gif";
import gif2 from "../../images/home/videos/robot 2.gif";
import video1 from "../../images/home/videos/home1.mp4";
import video2 from "../../images/home/videos/home2.mp4";
import frame1 from "../../images/home/Frame1.png";
import frame2 from "../../images/home/Frame2.png";
import frame3 from "../../images/home/Frame3.png";
import frame4 from "../../images/home/Frame4.png";
import frame5 from "../../images/home/Frame5.png";
import frame6 from "../../images/home/Frame6.png";
import frame7 from "../../images/home/Frame7.png";
import Carousel from "../../components/carousel/carousel";

const Home = (props) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className="home">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="home-hero"
        spacing={5}>
        <img src={gif1} alt="gif1" className="gif1" />
        <Grid item>
          <Typography className="heading">
            CHATGPT for your <br />
            PDF documents
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="hero-subheading">
            Say goodbye to the hassle of scrolling lengthy documents endlessly
            to find the <br /> information you need. Chatpdf will do the hard
            work for you.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="try-it-btn"
            onClick={props.onModalShown}>
            Try it now!
          </Button>
          <Typography className="btn-below-text">
            Try it in 3 simple steps!
          </Typography>
        </Grid>
        <Grid item></Grid>
        <img src={gif2} alt="gif2" className="gif2" />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={5}
        className="step-section">
        <div className="small-left-circle"></div>
        <div className="large-left-circle"></div>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: 2, md: 4 }}>
              <img src={frame1} alt="frame1" className="frame-img" />
              <div style={{ textAlign: "center" }}>
                <Typography className="step-bold-text">
                  Upload your PDFs
                </Typography>
                <Typography className="step-text">
                  Upload your PDF documents.{" "}
                </Typography>
              </div>
            </Stack>
            {!largeScreen && (
              <div>
                <img
                  src={frame5}
                  alt="frame1"
                  className="frame-img single-step"
                />
              </div>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={4}>
            {!largeScreen && (
              <div>
                <img
                  src={frame6}
                  alt="frame1"
                  className="frame-img single-step"
                />
              </div>
            )}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: 2, md: 4 }}>
              <img src={frame2} alt="frame2" className="frame-img" />
              <div style={{ textAlign: "center" }}>
                <Typography className="step-bold-text">
                  Our AI will get to work
                </Typography>
                <Typography className="step-text">
                  We process your documents using the latest AI technology.{" "}
                </Typography>
              </div>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: 2, md: 4 }}>
              <img src={frame3} alt="frame3" className="frame-img" />
              <div style={{ textAlign: "center" }}>
                <Typography className="step-bold-text">
                  Chat with your documents
                </Typography>
                <Typography className="step-text">
                  Ask questions and find relevant information faster.
                </Typography>
              </div>
            </Stack>
            {!largeScreen && (
              <div>
                <img
                  src={frame7}
                  alt="frame1"
                  className="frame-img single-step"
                />
              </div>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {largeScreen && (
            <div>
              <img src={frame4} alt="frame1" className="frame-img" />
            </div>
          )}
        </Grid>
        <div className="small-right-circle"></div>
        <div className="large-right-circle"></div>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 5, md: 10 }}
        className="home-video-section">
        <div className="small-right-video-circle"></div>
        <Grid item className="home-video-content">
          <Typography className="heading">Features</Typography>
          <video src={video1} autoPlay loop muted className="home-video" />
        </Grid>

        <div className="large-left-video-circle"></div>

        <Grid item className="home-video-content">
          <Typography className="heading">
            Build custom data
            <br />
            from your documents
          </Typography>
          <div style={{ display: "grid" }}>
            <video
              src={video2}
              autoPlay
              loop
              muted
              className="home-video"></video>
            <Button variant="contained" className="video-btn">
              Try it now!
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={{ xs: 5, md: 10 }}>
        <Grid item>
          <Typography className="heading">FAQ's</Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            className="faq">
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={5}>
                <div>
                  <Typography className="faq-q">
                    How do I set this up for my organization?
                  </Typography>
                  <Typography className="faq-ans">
                    Simply get in touch with us and we will provide a one-on-one
                    implementation that is suitable for your business and work
                    with you on every step, no matter how small or large your
                    organization is.
                  </Typography>
                </div>

                <div>
                  <Typography className="faq-q">
                    Where is my data stored?
                  </Typography>
                  <Typography className="faq-ans">
                    Your data security is our top priority. The content of the
                    documents that you have uploaded is hosted on secure GCP
                    servers in us-east.
                  </Typography>
                </div>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={5}>
                <div>
                  <Typography className="faq-q">
                    Does it support other languages?
                  </Typography>
                  <Typography className="faq-ans">
                    Yes, it supports over 95 languages. You can have your
                    documents in any language and ask it questions in any
                    language.
                  </Typography>
                </div>
                <div>
                  <Typography className="faq-q">
                    What should my data look like?
                  </Typography>
                  <Typography className="faq-ans">
                    You have the flexibility to upload multiple filetypes, paste
                    text, insert a URL to create a unique chatbot.
                  </Typography>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={{ xs: 5, md: 10 }}>
        <Grid item xs={12}>
          <Typography
            className="heading"
            align="center"
            lineHeight={1}
            padding={4}>
            Checkout what our <br /> users are saying
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Carousel />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
