import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";

import "./Pricing.css";

const PricingCard = ({
  title,
  content1,
  content2,
  price,
  type,
  isBorder = false,
  isHeader = false,
}) => {
  return (
    <Card
      sx={{
        maxWidth: "350px",
        margin: "auto",
        overflow: "hidden",
        border: isBorder ? "2px solid #C54B8C" : "none",
      }}
    >
      <Box
        sx={{
          display: isHeader ? "block" : "none",
          background: "#C54B8C",
          width: "100%",
          textAlign: "center",
          justifyContent: "cenetr",
          padding: "15px 0px",
        }}
      >
        <Typography
          color="white"
          sx={{ fontSize: { xs: "2.5rem", sm: "1.5rem" }, fontWeight: "bold" }}
        >
          Recommended
        </Typography>
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "6vh 5vw", sm: "6vh 2vw" },
          rowGap: "35px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" pt={5}>
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "2.5rem", sm: "1.5rem" }, textAlign: "center" }}
        >
          {content1} <br /> {content2}
        </Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h3" fontWeight="bold">
            {price}
          </Typography>
          <Typography variant="h3" fontSize={14}>
            / {type}
          </Typography>
        </Stack>
        <Button
          variant="contained"
          className={isHeader ? "get-start-btn2" : "get-start-btn1"}
        >
          Get Started
        </Button>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
