import React, { useEffect, useState } from "react";
import Logo from "../../images/ExpertsCloudLogo.png";
import "./header.css";
import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Slide from "@mui/material/Slide";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";

import { Link, useSearchParams } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [checked, setChecked] = useState(false);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useSearchParams();
  const link = window.location.pathname;

  return (
    <>
      {!largeScreen && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={`header ${isScrolled ? "scrolled" : ""}`}>
            <Link className="nav-links" to="/">
              <Stack direction="row" alignItems="center" spacing={1}>
                <img src={Logo} alt="logo" />
                <Typography className="brand-name">CHATPDF</Typography>
              </Stack>
            </Link>

            <Button sx={{ color: "white" }} onClick={handleChange}>
              <MenuIcon sx={{ fontSize: "35px", cursor: "pointer" }} />
            </Button>
          </Grid>

          <Box
            sx={{
              position: "fixed",
              width: "100%",
              zIndex: 100,
              overflow: "hidden",
            }}>
            <Slide direction="left" in={checked}>
              <Paper sx={{ maxWidth: "100%", borderRadius: "0" }}>
                <MenuList sx={{ display: "grid", gap: 2 }}>
                  <Link className="nav-links-sm" to="/">
                    <MenuItem>
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon
                          sx={{ fontSize: 24, marginRight: 3 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ sx: { fontSize: 16 } }}>
                        Features
                      </ListItemText>
                    </MenuItem>
                  </Link>

                  <Link className="nav-links-sm" to="/pricing">
                    <MenuItem>
                      <ListItemIcon>
                        <BookmarkBorderOutlinedIcon
                          sx={{ fontSize: 24, marginRight: 3 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ sx: { fontSize: 16 } }}>
                        Pricing
                      </ListItemText>
                    </MenuItem>
                  </Link>
                </MenuList>
              </Paper>
            </Slide>
          </Box>
        </>
      )}

      {largeScreen && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={`header ${isScrolled ? "scrolled" : ""}`}>
          <Link className="nav-links" to="/">
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={Logo} alt="logo" />
              <Typography className="brand-name">CHATPDF</Typography>
            </Stack>
          </Link>

          <Stack direction="row" spacing={3} alignItems="center">
            <Link
              className="nav-links"
              to="/"
              style={{ color: link === "/" ? "#C54B8C" : "white" }}>
              <Typography className="nav-item">Feature</Typography>
            </Link>
            <Link
              className="nav-links"
              to="/pricing"
              style={{ color: link === "/pricing" ? "#C54B8C" : "white" }}>
              <Typography className="nav-item">Pricing</Typography>
            </Link>
          </Stack>

          <Button variant="contained" className="get-start-btn">
            Get Started
          </Button>
        </Grid>
      )}
    </>
  );
};

export default Header;
