import React from "react";
import "./footer.css";
import { Button, Grid, Stack, Divider, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="footer"
      spacing={3}>
      <Grid item xs={6}>
        <Stack direction="row" spacing={1} alignItems="center">
          <a
            className="nav-links"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Expertscloud/">
            <FacebookIcon className="social-icon" />
          </a>
          <a
            className="nav-links"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/lifeatexpertscloud/">
            <InstagramIcon className="social-icon" />
          </a>
          <a
            className="nav-links"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/expertscloud-pvt-limited">
            <LinkedInIcon className="social-icon" />
          </a>
        </Stack>
      </Grid>

      <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="contained" className="get-start-btn">
          Get Started
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider color="#873E96" />
      </Grid>

      <Link className="nav-links" to="/">
        <Grid item xs={12} px={3}>
          <Typography className="brand-name">CHATPDF</Typography>
        </Grid>
      </Link>

      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography className="footer-text">
            &copy; 2023 ChatPDF. All rights reserved.
          </Typography>

          <Stack direction="row" spacing={5} alignItems="center">
            <Typography className="footer-text">Privacy Policy</Typography>
            <Typography className="footer-text">Terms of Services</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
