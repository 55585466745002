import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import Pricing from "./pages/pricing/Pricing";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PdfModal from "./components/modals/pdfModal/PdfModal";
import { useState } from "react";

const App = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="main-content">
      <BrowserRouter>
        <Header />
        <PdfModal onModalHide={handleClose} isOpen={open} />
        <Routes>
          <Route
            index
            element={
              <Home onModalHide={handleClose} onModalShown={handleOpen} />
            }
          />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
