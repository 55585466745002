import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Letter from "../../../images/gifs/letter.gif";
import Arrow from "../../../images/gifs/arrow.gif";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5px 0px white",
  borderRadius: "10px",
  overflow: "hidden",
};

export default function PdfModal(props) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div>
      <Modal open={props.isOpen} onClose={props.onModalHide}>
        <Box sx={style}>
          <Grid container position="relative" minHeight="100%">
            <Grid xs={12} md={3} item>
              <Stack
                py={4}
                bgcolor="#D9D9D9"
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                borderRadius="10px 10px 0px 0px">
                <Typography fontSize="2.2rem" fontWeight="bold">
                  Upload PDF
                </Typography>
                <AddCircleIcon color="secondary" sx={{ fontSize: "40px" }} />
              </Stack>
              <Stack
                bgcolor="rgba(217, 217, 217, 0.58)"
                height={{ sx: "auto", md: "100%" }}></Stack>
            </Grid>
            <Grid xs={12} md={9} item p={4}>
              <Box sx={{ position: "absolute", top: "5px", right: "5px" }}>
                <IconButton aria-label="close" onClick={props.onModalHide}>
                  <CancelOutlinedIcon
                    sx={{ fontSize: "2em", color: "black" }}
                  />
                </IconButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%">
                {largeScreen && (
                  <Box py={3}>
                    <Stack direction="row" gap={4} alignItems="center">
                      <Box sx={{ transform: "rotate(210deg)" }}>
                        <img src={Arrow} alt="" />
                      </Box>
                      <Typography variant="h4" color="grey">
                        How can i help you today?
                      </Typography>
                    </Stack>
                  </Box>
                )}
                <Box alignSelf="center">
                  <img src={Letter} alt="" />
                </Box>
                <Box>
                  <IconTextField
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    fullWidth
                    placeholder="Enter text here..."
                    color="grey"
                    iconEnd={<SendIcon sx={{ color: "black", fontSize: 25 }} />}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};
