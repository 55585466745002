import React, { useState } from "react";
import { Box, Typography, Grid, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Pricing.css";
import PricingCard from "./PricingCard";
import discountImg from "../../images/Vector 6.png";

const Pricing = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="pricing">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: "10vh 0",
        }}>
        <Typography className="heading" textAlign="center">
          We offer great price plan for you!
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          typography: "body2",
        }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "end",
              position: "relative",
              gap: "30px",
            }}>
            <Typography variant="h5" color="white" pb={1}>
              SAVE UP TO 30%
            </Typography>
            <Box sx={{ position: "absolute", top: "60px", right: "100px" }}>
              <img src={discountImg} alt="" />
            </Box>
            <Tabs
              className="pricing-list"
              onChange={handleChange}
              aria-label="pricing"
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#C54B8C",
                  height: "100%",
                  borderRadius: "30px",
                },
              }}>
              <Tab
                className="pricing-tab"
                sx={{
                  fontSize: "12px",
                  textTransform: " capitalize",
                }}
                label="Monthly"
                value="1"
              />
              <Tab
                className="pricing-tab"
                sx={{ fontSize: "12px", textTransform: " capitalize" }}
                label="Yearly"
                value="2"
              />
            </Tabs>
          </Box>

          <TabPanel value="1" sx={{ padding: "10vh 20px" }}>
            <Grid
              spacing={4}
              container
              alignItems="end"
              justifyContent="center">
              <Grid xs={12} md={4} item>
                <PricingCard
                  isBorder={true}
                  title="Starter"
                  content1="More power for small teams.Create project plans with confidence."
                  content2="Upload up to 10 PDF document."
                  price="Free"
                  type="month"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <PricingCard
                  title="Business"
                  content1="For companies that need to manage work happening across multiple teams."
                  content2="Upload up to 30 PDF document."
                  price="$200"
                  type="month"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <PricingCard
                  isHeader={true}
                  title="Enterprise"
                  content1="For enterprise that need additional security, control and support."
                  content2=" Upload unlimited PDF document."
                  price="$400"
                  type="month"
                />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="2" sx={{ padding: "10vh 20px" }}>
            <Grid
              spacing={4}
              container
              alignItems="end"
              justifyContent="center">
              <Grid xs={12} md={4} item>
                <PricingCard
                  isBorder={true}
                  title="Starter"
                  content1="More power for small teams.Create project plans with confidence."
                  content2="Upload up to 10 PDF document."
                  price="Free"
                  type="month"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <PricingCard
                  title="Business"
                  content1="For companies that need to manage work happening across multiple teams."
                  content2="Upload up to 30 PDF document."
                  price="$600"
                  type="Annually"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <PricingCard
                  isHeader={true}
                  title="Enterprise"
                  content1="For enterprise that need additional security, control and support."
                  content2=" Upload unlimited PDF document."
                  price="$900"
                  type="Annually"
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Pricing;
